<template>
  <div class="view view_Mitglieder" v-if="doRender">
    <viewTabsTop v-if="tabs.length > 1" :tabs="tabs" />
    <!-- CONTENT -->
    <transition
      v-if="
        currentTab.code === ''
        && !currentTab.query.u
        && tabs.filter((o) => o.erlaubt === 'x|100|000').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Mitgliederliste
        :save="save"
      />
    </transition>
    <transition
      v-else-if="
        currentTab.code === ''
        && currentTab.query.u
        && tabs.filter((o) => o.erlaubt === 'x|100|000').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Mitglied
        :id="currentTab.query.u"
        :save="save"
      />
    </transition>
    <transition
      v-else-if="
        currentTab.code === '010'
        && tabs.filter((o) => o.erlaubt === 'x|100|010').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Einstellungen
        :save="save"
      />
    </transition>
    <p v-else>
      Zugriff verweigert
    </p>
    <!-- CONTENT -->
    <viewTabsBottom v-if="tabs.length > 1" :tabs="tabs" />
  </div>
</template>

<script>
import 'animate.css';
import $ from 'jquery';
import ViewTabsTop from '@/components/ViewTabsTop.vue';
import ViewTabsBottom from '@/components/ViewTabsBottom.vue';

import Einstellungen from '@/components/Funktionen/100/Mitglieder/Einstellungen/Einstellungen.vue';
import Mitgliederliste from '@/components/Funktionen/100/Mitglieder/Mitgliederliste/Mitgliederliste.vue';
import Mitglied from '@/components/Funktionen/100/Mitglieder/Mitgliederliste/Mitglied.vue';

export default ({
  name: 'Mitglieder',
  components: {
    ViewTabsTop,
    ViewTabsBottom,
    Einstellungen,
    Mitgliederliste,
    Mitglied,
  },
  data: () => ({
    save: {
      loading: false,
      anzeigeGruppen: [],
    },
    doRender: false,
    tabs: [],
    mytabs: [
      {
        icon: 'mdi-account-edit',
        title: 'Mitglieder',
        route: '/100',
        erlaubt: 'x|100|000',
      },
      {
        icon: 'mdi-tune',
        title: 'Einstellungen',
        route: '/100-010',
        erlaubt: 'x|100|010',
      },
    ],
    currentTab: {
      code: '',
      query: {},
    },
  }),
  computed: {
    transitionIn() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeInLeft';
      }
      return result;
    },
    transitionOut() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeOutLeft';
      }
      return result;
    },
  },
  methods: {
    async tryGetSettings() {
      if (localStorage.getItem('f100')) {
        const help = JSON.parse(localStorage.getItem('f100'));
        if (help.save) {
          this.save = help.save;
        }
      }
      this.save.loading = true;
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/100/100/?func=getSettings`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.save = response.save;
          }
          let help = {
            save: {},
          };
          if (localStorage.getItem('f100')) {
            help = JSON.parse(localStorage.getItem('f100'));
          }
          help.save = this.save;
          localStorage.setItem('f100', JSON.stringify(help));
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
        },
        complete: () => {
          // this.save.loading = false;
        },
      });
    },
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    this.tryGetSettings();
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      window.location.reload();
    }
    // Tabs erstellen:
    this.mytabs.forEach((tab) => {
      if (
        this.$store.state.main.save.user.rechte.find((element) => {
          let bool = false;
          if (element.includes(tab.erlaubt)) {
            bool = true;
          }
          return bool;
        })
      ) {
        this.tabs.push(tab);
      }
    });
    // Wenn der aktuelle Tabs nicht erlaubt ist, zum ersten erlaubten Tab navigieren:
    if (
      this.tabs.filter((o) => o.route === this.$router.history.current.path).length <= 0
      && this.tabs.length > 0
    ) {
      this.$router.push({ path: this.tabs[0].route });
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neue Tab innerhalb dieser Seite aufgerufen wird
    this.setCurrentTab(to);
    next();
  },
});
</script>

<style>
</style>
