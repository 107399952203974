<template>
  <div style="display:inline-block;">
    <v-dialog
      v-if="item.entlassgrund.includes('Regelausnahme')"
      v-model="item.anwesenheitHeute.dialog_regelausnahme"
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="error"
          v-bind="attrs"
          v-on="on"
        >
          <span
            v-if="item.anwesend"
          >
            {{ item.anwesendHeuteBis }}
          </span>
          <span
            v-else
          >
            NICHT
          </span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          {{ item.vorname }} {{ item.nachname }} ({{ item.name_klasse }})
          <br>
          Regelausnahme
        </v-card-title>
        <v-card-text>
          {{ item.anwesenheitHeute.entlassgrundRegelausnahme }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="item.anwesenheitHeute.dialog_regelausnahme = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="
        item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1').length > 0
        && item.entlassgrund.includes('Ausnahme')
      "
      v-model="item.anwesenheitHeute.dialog_ausnahme"
      max-width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="
            item.anwesend
          "
          text
          color="error"
          v-bind="attrs"
          v-on="on"
        >
          {{ item.anwesendHeuteBis }}
        </v-btn>
        <v-btn
          v-else-if="
            (
              item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1')[0]
                .entlasszeit
              !== item.anwesendHeuteBis
              && item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1')[0]
                .elternErstellt
            )
          "
          small
          color="error"
          v-bind="attrs"
          v-on="on"
        >
          {{
            item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1')[0].entlasszeit
          }} !
        </v-btn>
        <v-btn
          v-else
          text
          color="error"
          v-bind="attrs"
          v-on="on"
        >
          Nicht
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          {{ item.vorname }} {{ item.nachname }} ({{ item.name_klasse }})
        </v-card-title>
        <v-card-text>
          <div v-if="item.anwesend">
            <v-alert
              type="error"
              border="bottom"
              colored-border
              v-if="
                item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1')[0]
                  .entlasszeit
                !== item.anwesendHeuteBis
                && item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1')[0]
                  .elternErstellt
                && item.anwesenheitsstatus !== 0
              "
            >
              Die Ausnahme wurde am
              {{ item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1')[0]
                .elternErstellt_tag }}
              um
              {{ item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1')[0]
                .elternErstellt_zeit }}
              Uhr erstellt, nachdem das Kind bereits für
              {{ item.anwesendHeuteBis }} Uhr
              entlassen wurde:
            </v-alert>
            <div
              v-if="
                item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1').length > 0
              "
            >
              Ausnahme Heute bis
              {{
                item.ausnahmen
                  .filter((o) => o.tag === tagHeute && o.aktiv === '1')[0].entlasszeit
              }} Uhr.
            </div>
            <p>"{{ item.anwesenheitHeute.entlassgrundAusnahme }}"</p>
            <v-alert
              type="warning"
              border="bottom"
              colored-border
              v-if="
                item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1')[0]
                  .entlasszeit
                !== item.anwesendHeuteBis
                && item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === '1')[0]
                  .elternErstellt
                && item.anwesenheitsstatus !== 0
              "
            >
              Um die Ausnahme umzusetzen, stellen Sie das Kind von "entlassen" wieder
              auf "anwesend" um.
            </v-alert>
          </div>
          <div v-if="!item.anwesend">
            Ausnahme Heute nicht anwesend.
            <p>"{{ item.anwesenheitHeute.entlassgrundAusnahme }}"</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="item.anwesenheitHeute.dialog_ausnahme = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-if="item.entlassgrund.includes('Standardentlasszeit')"
      text
      style="pointer-events:none;"
    >
      {{ item.anwesendHeuteBis }}
    </v-btn>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      default() { return {}; },
    },
    tagHeute: {
      type: String,
      default: '',
    },
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
  },
};
</script>
