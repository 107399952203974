<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-4">
      <p>
        Bis wann sollen Eltern die Möglichkeit haben eine Meldung zu senden?
      </p>
      <p style="font-size:80%;">
        Bis zu dieser Uhrzeit sollten keine Kinder auf "entlassen" umgestellt werden.
      </p>
      <v-row>
        <v-col>
          <TimePicker
            label="Meldung spätestens bis"
            icon="mdi-clock-time-four"
            v-model="anwesenheitMeldungSpaetestensBis"
          />
        </v-col>
      </v-row>
      <p>
        Für welche Entlasszeiten können Meldungen gemacht werden?
      </p>
      <v-row>
        <v-col>
          <v-row>
            <v-col
              cols="6"
              v-for="(zeit, idx) in moeglicheEntlasszeiten"
              :key="idx"
            >
              <v-row align="center">
                <v-col>
                  <TimePicker
                    label="Entlasszeit"
                    icon=""
                    v-model="
                      zeit.zeit
                    "
                  />
                </v-col>
                <v-col cols="4">
                  <v-dialog
                    v-if="moeglicheEntlasszeiten_bedingungen[zeit.zeit]"
                    v-model="dialogsEntlasszeitenConditions[idx]"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        color="primary"
                        class="ma-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-vector-polyline</v-icon>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title>
                        Bedingungen für {{ zeit.zeit }}
                      </v-card-title>

                      <v-card-text>
                        <v-checkbox
                          label="Bedingungen aktiv"
                          v-model="moeglicheEntlasszeiten_bedingungen[zeit.zeit].aktiv"
                        />
                        <v-select
                          multiple
                          label="Klassen"
                          v-model="moeglicheEntlasszeiten_bedingungen[zeit.zeit].klassen"
                          :items="prop_settings.klassen"
                          item-text="name"
                          item-value="id"
                        />
                        <DatePicker
                          label="Erlaubt von"
                          v-model="moeglicheEntlasszeiten_bedingungen[zeit.zeit].datumVon"
                        />
                        <DatePicker
                          label="Erlaubt bis"
                          v-model="moeglicheEntlasszeiten_bedingungen[zeit.zeit].datumBis"
                        />
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="dialogsEntlasszeitenConditions[idx] = false"
                        >
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    x-small
                    color="error"
                    class="ma-1"
                    @click="moeglicheEntlasszeiten.splice(idx, 1);"
                  >
                    <v-icon small>mdi-minus-box</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn
                small
                color="primary"
                @click="
                  moeglicheEntlasszeiten.push({
                    zeit: '00:00',
                  });
                "
              >
                <v-icon left>mdi-plus-box</v-icon>
                Entlasszeit
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            auto-grow
            label="Info"
            v-model="text_alertVorMeldungsformular"
            hint=""
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>
            Sollen Eltern die Möglichkeit haben,
            Dokumente (z.B. ein Attest o.Ä.)
            zu einer Meldung hochzuladen?
          </p>
          <v-checkbox
            v-model="meldung_erlaubeDateiupload"
            label="Erlaube Dateiupload"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            small
            color="success"
            class="ml-2"
            :disabled="
              !xChanged.moeglicheEntlasszeiten
              && !xChanged.moeglicheEntlasszeiten_bedingungen
              && !xChanged.anwesenheitMeldungSpaetestensBis
              && !xChanged.meldung_erlaubeDateiupload
              && !xChanged.text_alertVorMeldungsformular
            "
            @click="$emit('saveSettings', {
              func: 'saveSettings',
              anwesenheitMeldungSpaetestensBis,
              moeglicheEntlasszeiten,
              moeglicheEntlasszeiten_bedingungen,
              meldung_erlaubeDateiupload,
              text_alertVorMeldungsformular,
             })"
          >
            <v-icon
            left
            >mdi-content-save</v-icon>
            Änderungen Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';
import TimePicker from '@/components/Inputs/TimePicker.vue';
import DatePicker from '@/components/Inputs/DatePicker.vue';

export default {
  components: {
    TimePicker,
    DatePicker,
  },
  props: {
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    monate: [
      { name: 'Januar', value: '01' },
      { name: 'Februar', value: '02' },
      { name: 'März', value: '03' },
      { name: 'April', value: '04' },
      { name: 'Mai', value: '05' },
      { name: 'Juni', value: '06' },
      { name: 'Juli', value: '07' },
      { name: 'August', value: '08' },
      { name: 'September', value: '09' },
      { name: 'Oktober', value: '10' },
      { name: 'November', value: '11' },
      { name: 'Dezember', value: '12' },
    ],
    dialogsEntlasszeitenConditions: {},
    xLoaded: {
      meldung_erlaubeDateiupload: false,
      text_alertVorMeldungsformular: '',
    },
    xChanged: {
      moeglicheEntlasszeiten: false,
      moeglicheEntlasszeiten_bedingungen: false,
      anwesenheitMeldungSpaetestensBis: false,
      meldung_erlaubeDateiupload: false,
      text_alertVorMeldungsformular: false,
    },
    anwesenheitMeldungSpaetestensBis: null,
    moeglicheEntlasszeiten: [],
    moeglicheEntlasszeiten_bedingungen: {},
    meldung_erlaubeDateiupload: false,
    text_alertVorMeldungsformular: '',
  }),
  computed: {
  },
  watch: {
    prop_settings() {
      this.getData();
    },
    moeglicheEntlasszeiten: {
      handler(neu) {
        if (JSON.stringify(neu) !== this.xLoaded.moeglicheEntlasszeiten) {
          this.xChanged.moeglicheEntlasszeiten = true;
          const moeglicheEntlasszeitenBedingungen = JSON.parse(JSON.stringify(
            this.moeglicheEntlasszeiten_bedingungen,
          ));
          neu.forEach((e) => {
            if (!moeglicheEntlasszeitenBedingungen[e.zeit]) {
              // Wenn die Entlasszeit keine Bedingungen hat
              let neueBedingung = {
                aktiv: false,
                klassen: [],
                datumVon: '',
                datumBis: '',
              };
              Object.keys(moeglicheEntlasszeitenBedingungen).forEach((k) => {
                // Suche nach Bedingungen, für die es keine Entlasszeit mehr gibt
                if (neu.filter((o) => o.zeit === k).length <= 0) {
                  // Wenn eine Bedingung gefunden wurde, für die es keine Entlasszeit gibt,
                  // wurde die Uhrzeit geändert, also nimm die Bedingung mit
                  neueBedingung = moeglicheEntlasszeitenBedingungen[k];
                }
              });
              moeglicheEntlasszeitenBedingungen[e.zeit] = neueBedingung;
            }
          });
          this.moeglicheEntlasszeiten_bedingungen = moeglicheEntlasszeitenBedingungen;
        } else {
          this.xChanged.moeglicheEntlasszeiten = false;
        }
      },
      deep: true,
    },
    moeglicheEntlasszeiten_bedingungen: {
      handler(neu) {
        if (JSON.stringify(neu) !== this.xLoaded.moeglicheEntlasszeiten_bedingungen) {
          this.xChanged.moeglicheEntlasszeiten_bedingungen = true;
        } else {
          this.xChanged.moeglicheEntlasszeiten_bedingungen = false;
        }
      },
      deep: true,
    },
    anwesenheitMeldungSpaetestensBis(neu) {
      if (this.xLoaded.anwesenheitMeldungSpaetestensBis !== JSON.stringify(neu)) {
        this.xChanged.anwesenheitMeldungSpaetestensBis = true;
      } else {
        this.xChanged.anwesenheitMeldungSpaetestensBis = false;
      }
    },
    meldung_erlaubeDateiupload(neu) {
      if (neu !== this.xLoaded.meldung_erlaubeDateiupload) {
        this.xChanged.meldung_erlaubeDateiupload = true;
      } else {
        this.xChanged.meldung_erlaubeDateiupload = false;
      }
    },
    text_alertVorMeldungsformular(neu) {
      if (neu !== this.xLoaded.text_alertVorMeldungsformular) {
        this.xChanged.text_alertVorMeldungsformular = true;
      } else {
        this.xChanged.text_alertVorMeldungsformular = false;
      }
    },
  },
  methods: {
    getData() {
      if (this.prop_settings.anwesenheitMeldungSpaetestensBis) {
        this.anwesenheitMeldungSpaetestensBis = this.prop_settings.anwesenheitMeldungSpaetestensBis;
        this.xLoaded.anwesenheitMeldungSpaetestensBis = JSON.stringify(
          this.anwesenheitMeldungSpaetestensBis,
        );
      }
      let moeglicheEntlasszeitenBedingungen = {};
      if (this.prop_settings.moeglicheEntlasszeiten_bedingungen) {
        moeglicheEntlasszeitenBedingungen = JSON.parse(JSON.stringify(
          this.prop_settings.moeglicheEntlasszeiten_bedingungen,
        ));
      }
      if (this.prop_settings.moeglicheEntlasszeiten) {
        const help = [];
        this.prop_settings.moeglicheEntlasszeiten.forEach((z) => {
          if (!moeglicheEntlasszeitenBedingungen[z]) {
            moeglicheEntlasszeitenBedingungen[z] = {
              aktiv: false,
              klassen: [],
              datumVon: '',
              datumBis: '',
            };
          }
          help.push({
            zeit: z,
          });
        });
        if (JSON.stringify(help) !== this.xLoaded.moeglicheEntlasszeiten) {
          this.moeglicheEntlasszeiten = help;
          this.xLoaded.moeglicheEntlasszeiten = JSON.stringify(this.moeglicheEntlasszeiten);
          this.xChanged.moeglicheEntlasszeiten = false;
        }
      }
      if (
        JSON.stringify(moeglicheEntlasszeitenBedingungen) !== this.xLoaded
          .moeglicheEntlasszeiten_bedingungen
      ) {
        this.moeglicheEntlasszeiten_bedingungen = moeglicheEntlasszeitenBedingungen;
        this.xLoaded.moeglicheEntlasszeiten_bedingungen = JSON.stringify(
          this.moeglicheEntlasszeiten_bedingungen,
        );
        this.xChanged.moeglicheEntlasszeiten_bedingungen = false;
      }
      // if (this.prop_settings.meldung_erlaubeDateiupload) {
      this.meldung_erlaubeDateiupload = this.prop_settings.meldung_erlaubeDateiupload;
      this.xLoaded.meldung_erlaubeDateiupload = this.meldung_erlaubeDateiupload;
      // }
      if (this.meldung_erlaubeDateiupload === this.xLoaded.meldung_erlaubeDateiupload) {
        this.xChanged.meldung_erlaubeDateiupload = false;
      }
      if (this.prop_settings.text_alertVorMeldungsformular) {
        this.text_alertVorMeldungsformular = this.prop_settings.text_alertVorMeldungsformular;
        this.xLoaded.text_alertVorMeldungsformular = this.text_alertVorMeldungsformular;
      }
      if (this.text_alertVorMeldungsformular === this.xLoaded.text_alertVorMeldungsformular) {
        this.xChanged.text_alertVorMeldungsformular = false;
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>
