var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-alert',{class:{'hide': (
    _vm.meldung.anzeigePlanen
    &&
    (
      _vm.timestampNow - _vm.meldung.von_timestamp < 0
      || (
        _vm.meldung.bis_timestamp - _vm.timestampNow < 0
        && _vm.meldung.bis_timestamp > 0
      )
    )
  )},attrs:{"border":"bottom","colored-border":"","elevation":"2","icon":_vm.meldung.icon,"color":_vm.meldung.color}},[_c('v-row',{attrs:{"align":"center"}},[(!_vm.prop_settings.w1_bedingung_meldungErstellen_nutzer)?_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.meldung.titel)+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.meldung.textNl2br)}})]):_vm._e(),(_vm.prop_settings.w1_bedingung_meldungErstellen_nutzer)?_c('v-col',{attrs:{"cols":"10","sm":"11"}},[_c('h3',[_vm._v(_vm._s(_vm.meldung.titel))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.meldung.textNl2br)}})]):_vm._e(),(_vm.prop_settings.w1_bedingung_meldungErstellen_nutzer)?_c('v-col',{attrs:{"cols":"2","sm":"1","align":"center"}},[_c('v-row',[(_vm.typ === 'alert')?_c('v-col',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,1269401092),model:{value:(_vm.dialogs_delNews[_vm.meldung.id]),callback:function ($$v) {_vm.$set(_vm.dialogs_delNews, _vm.meldung.id, $$v)},expression:"dialogs_delNews[meldung.id]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Sicher? ")]),_c('v-card-text',{staticClass:"pt-8"},[_c('p',[_vm._v("Die Meldung "),_c('b',[_vm._v(_vm._s(_vm.meldung.titel))]),_vm._v(" unwiderruflich löschen?")])]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogs_delNews[_vm.meldung.id] = false}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.loading.delMeldung},on:{"click":function($event){return _vm.$emit('saveNews', 'delMeldung', _vm.meldung.id);}}},[_vm._v(" Löschen ")])],1)],1)],1)],1):_c('v-col',[_c('v-btn',{staticClass:"my-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('closePopup', {})}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"my-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('editNews', _vm.meldung);}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],1)],1)],1):_vm._e()],1),(_vm.meldung.rueckmeldung.nutzen)?_c('div',[_c('div',{attrs:{"align":"right"}},[(_vm.prop_settings.w1_bedingung_meldungErstellen_nutzer)?_c('Widget1NewsfeedRueckmeldungsergebnisse',{attrs:{"prop_meldung":_vm.meldung},on:{"refresh":function($event){return _vm.$emit('refreshUsers', _vm.meldung.id, $event)}}}):_vm._e()],1),(
        _vm.meldung.rueckmeldung.user.kannAendern
        || !_vm.prop_settings.w1_bedingung_meldungErstellen_nutzer
      )?_c('v-card',{staticClass:"my-4 pt-2 pa-6"},[_c('EditUserParameter',{attrs:{"user":_vm.meldung.rueckmeldung.user,"parameter":_vm.meldung.rueckmeldung.formularfelder,"disabled":!_vm.meldung.rueckmeldung.user.kannAendern}})],1):_vm._e(),(_vm.meldung.rueckmeldung.user.kannAendern)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"x-small":"","loading":_vm.loading.saveRueckmeldung},on:{"click":function($event){return _vm.$emit('saveNews', 'saveRueckmeldung', { meldung: _vm.meldung, kinder: _vm.$store.state.main.save.user.f200.kinder });}}},[_vm._v(" Angaben abschicken ")])],1):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0"},[(
        _vm.meldung.anzeigePlanen
        && _vm.prop_settings.w1_bedingung_meldungErstellen_nutzer
      )?_c('span',{staticStyle:{"font-size":"70%"}},[_vm._v(" "+_vm._s(new Date(_vm.meldung.von_date).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }))+" "+_vm._s(_vm.meldung.von_time)+" - "),(
          _vm.meldung.von_date !== _vm.meldung.bis_date
        )?_c('span',[_vm._v(" "+_vm._s(new Date(_vm.meldung.bis_date).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.meldung.bis_time)+" "),(
          _vm.meldung.sendmailsettings
        )?_c('span',[_vm._v(" [ Sende per E-Mail ] ")]):_vm._e()]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }