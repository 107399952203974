<template>
  <v-container class="ma-0 pa-4 pt-0">
    <h2>Anwesenheiten Ausnahmen</h2>
    <v-row align="center">
      <v-col align="right">
        <DatePicker v-model="tag" />
      </v-col>
      <v-col align="right">
        <v-btn
          x-small
          class="me-2"
          @click="getAusnahmenHeute();"
          :disabled="tag === heute"
        >
          <v-icon small left>mdi-calendar-today</v-icon>
          Heute
        </v-btn>
        <v-btn
          x-small
          class="me-2"
          @click="tryGetAusnahmen();"
          :loading="loading"
        >
          <v-icon small left>mdi-update</v-icon>
          Aktualisieren
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="my-0" align="center">
      <v-col class="py-0"
      >
        <v-row align="center">
          <v-col>
            <v-select
              multiple
              label="Klassen anzuzeigen"
              :items="klassenSortiert"
              v-model="showKlassen"
              item-text="name"
              item-value="id"
              no-data-text="Keine Klassen verfügbar"
            >
              <template v-slot:selection="data">
                <!-- <span
                  v-if="
                    klassenSortiert
                      .filter((o) => showKlassen.includes(o.id)).length === klassenSortiert.length
                  "
                >
                  Alle
                </span> -->
                <span
                  v-if="
                    data.index === 0
                  "
                >
                  <span
                    class="grey--text text-caption"
                    v-for="(k, idx) in klassenSortiert"
                    :key="idx"
                  >
                    <v-chip x-small
                      v-if="showKlassen.filter((o) => o === k.id).length > 0"
                      close
                      :input-value="k"
                      @click:close="
                        removeAngezeigteKlasse(k.id)
                      "
                    >{{ k.name }}</v-chip>
                  </span>
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="3" align="left">
            <v-btn x-small
              @click="filterKlassenAlle();"
              :disabled="
                klassenSortiert
                  .filter((o) => showKlassen.includes(o.id)).length === klassenSortiert.length
              "
            ><v-icon>mdi-set-all</v-icon></v-btn>
            <br/>
            <v-btn x-small
              @click="filterKlassenKeine();"
              :disabled="
                klassenSortiert
                  .filter((o) => showKlassen.includes(o.id)).length <= 0
              "
            ><v-icon>mdi-set-none</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="py-0" cols="12" sm="6">
        <v-row align="center">
          <v-col>
            <v-select
              multiple
              label="Entlasszeiten filter"
              :items="alleEntlasszeiten"
              v-model="filterAlleEntlasszeiten"
              item-text="name"
              item-value="id"
              no-data-text="Keine Entlasszeiten verfügbar"
            >
              <template v-slot:selection="data">
                <span
                  v-if="data.index === 0"
                >
                  <span
                    class="grey--text text-caption"
                    v-for="(k, idx) in filterAlleEntlasszeitenSort"
                    :key="idx"
                  >
                    <v-chip x-small
                      close
                      :input-value="k"
                      @click:close="
                        removefilterAlleEntlasszeiten(k)
                      "
                    >{{ k }}</v-chip>
                  </span>
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="3" align="left">
            <v-btn x-small
              @click="filterAlleEntlasszeitenAlle();"
              :disabled="filterAlleEntlasszeiten.length === alleEntlasszeiten.length"
            ><v-icon>mdi-set-all</v-icon></v-btn>
            <br/>
            <v-btn x-small
              @click="filterAlleEntlasszeitenKeine();"
              :disabled="filterAlleEntlasszeiten.length <= 0"
            ><v-icon>mdi-set-none</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <span style="font-size:70%;">
      [Stand: {{ timeLastUpdate }}]
    </span>
    <v-text-field
      clearable dense
      label="Suchen..."
      append-icon="mdi-magnify"
      v-model="search"
      :rules="[
        $store.state.main.inputValidationRules.name,
      ]"
      class="ma-0"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="
        ausnahmen.filter((o) => {
          if (klassen.filter((k) => k.id === o.kind_id_klasse).length > 0) {
            if (showKlassen.indexOf(o.kind_id_klasse) >= 0) {
              return o;
            }
          }
        })
        .filter((o) => this.filterAlleEntlasszeiten.includes(o.entlasszeit))
      "
      :search="search"
      :loading="loading"
      :items-per-page="10"
      :header-props="headerProps"
      :footer-props="footerProps"
      no-data-text="Keine Ausnahmen gefunden"
      no-results-text="Keine Ausnahmen gefunden"
      loading-text="Lade Daten... Bitte warten"
      mobile-breakpoint="600"
    >
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
      </template>
      <template v-slot:item.entlasszeit="{ item }">
        <span
          v-if="item.anwesend === '0'"
          class="error--text"
        >
          NICHT
        </span>
        <span
          v-if="item.anwesend === '1'"
        >
          {{ item.entlasszeit }}
        </span>
      </template>
      <template v-slot:item.aktion="{ item }">
        <v-dialog
          v-model="dialog_showAusnahmeInfo[item.id]"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            text
            color="primary"
            v-bind="attrs"
            v-on="on"
            >
              <v-icon small>mdi-information</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{ item.kind_vorname }} {{ item.kind_nachname }} ({{ item.kind_klasse }})
            </v-card-title>
            <v-card-text class="pt-4">
              <p
                v-if="item.elternErstellt"
                style="font-size:80%;"
              >
                Durch einen Erziehungsberechtigten am
                {{ item.elternErstellt_tag }} um
                {{ item.elternErstellt_zeit }} Uhr:
              </p>
              <p
                v-if="item.elternErstellt"
              >
                Hiermit bestätige ich, dass mein Kind
                <b>{{ item.kind_vorname }} {{ item.kind_nachname }}</b>
                <b>{{ ` ${item.anwesenheit_text}` }}</b>
              </p>
              <p
                v-if="!item.elternErstellt"
              >
                <b>{{ item.kind_vorname }} {{ item.kind_nachname }}</b>
                <b>{{ item.anwesenheit_text }}</b>
              </p>
              <div
                v-if="item.kommentar.split(' ').join('') !== ''"
                class="pb-2"
              >
                <v-divider class="pb-2" />
                "<i>{{ item.kommentar }}</i>"
              </div>
              <div
                v-if="item.anhaenge.length > 0"
                class="pb-2"
              >
                <v-divider class="pb-2" />
                <span
                  v-if="item.anhaenge.length === 1"
                >
                  Anhang:
                </span>
                <span
                  v-if="item.anhaenge.length > 1"
                >
                  Anhänge:
                </span>
                <v-btn
                  x-small
                  v-for="(anh, idx) in item.anhaenge"
                  :key="idx"
                  @click="tryDownloadDokument(anh.pfad, anh.name);"
                  max-width="100%"
                  style="overflow:hidden;"
                >
                  {{ anh.name }}
                </v-btn>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="
                  dialog_showAusnahmeInfo[item.id] = false;
                "
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import $ from 'jquery';
import DatePicker from '@/components/Inputs/DatePicker.vue';

export default {
  components: {
    DatePicker,
  },
  props: {
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_widget: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    heute: null,
    tag: null,
    dialog_showAusnahmeInfo: {},
    loading: false,
    offline: false,
    ausnahmen: [],
    klassen: [],
    klassenIds: [],
    showKlassen: [],
    filterAlleEntlasszeiten: [],
    timeLastUpdate: '-',
    search: '',
    headers: [
      { text: 'Nachname', value: 'kind_nachname' },
      { text: 'Vorname', value: 'kind_vorname' },
      { text: 'Klasse', value: 'kind_klasse' },
      { text: 'Entlasszeit', value: 'entlasszeit' },
      { text: '', value: 'aktion', sortable: false },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
    klassenSortiert() {
      const help = JSON.parse(JSON.stringify(this.klassen));
      help.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return help;
    },
    filterAlleEntlasszeitenSort() {
      const neuFilterAlleEntlasszeiten = JSON.parse(JSON.stringify(this.filterAlleEntlasszeiten));
      neuFilterAlleEntlasszeiten.sort();
      return neuFilterAlleEntlasszeiten;
    },
    alleEntlasszeiten() {
      const entlasszeiten = [];
      this.ausnahmen.forEach((e) => {
        if (!entlasszeiten.includes(e.entlasszeit)) {
          entlasszeiten.push(e.entlasszeit);
        }
      });
      entlasszeiten.sort();
      return entlasszeiten;
    },
  },
  watch: {
    /* showKlassen(neu) {
      localStorage.setItem('f000_w2_showK', JSON.stringify(neu));
    }, */
    tag() {
      this.tryGetAusnahmen();
    },
  },
  methods: {
    filterKlassenAlle() {
      this.showKlassen = [];
      this.ausnahmen.forEach((k) => {
        if (!this.showKlassen.includes(k.kind_id_klasse)) {
          this.showKlassen.push(k.kind_id_klasse);
        }
      });
    },
    filterKlassenKeine() {
      this.showKlassen = [];
    },
    removeAngezeigteKlasse(id) {
      this.showKlassen.splice(this.showKlassen.indexOf(id), 1);
    },
    filterAlleEntlasszeitenAlle() {
      this.filterAlleEntlasszeiten = [];
      this.alleEntlasszeiten.forEach((k) => {
        this.filterAlleEntlasszeiten.push(k);
      });
    },
    filterAlleEntlasszeitenKeine() {
      this.filterAlleEntlasszeiten = [];
    },
    removefilterAlleEntlasszeiten(k) {
      this.filterAlleEntlasszeiten.splice(this.filterAlleEntlasszeiten.indexOf(k), 1);
    },
    getAusnahmenHeute() {
      const jetzt = new Date();
      const YYYY = jetzt.getFullYear();
      const mm = String(jetzt.getMonth() + 1).padStart(2, '0');
      const dd = String(jetzt.getDate()).padStart(2, '0');
      this.tag = `${YYYY}-${mm}-${dd}`;
      this.heute = `${YYYY}-${mm}-${dd}`;
    },
    async tryGetAusnahmen() {
      this.loading = true;
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/000/000/?func=w2`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          tag: this.tag,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            // console.log(response);
            this.ausnahmen = response.ausnahmen;
            this.klassen = response.klassenVorhanden;
            this.klassenIds = response.klassenVorhandenIds;
            this.timeLastUpdate = response.timeLastUpdate;
            if (!localStorage.getItem('f200_filterClass')) {
              this.showKlassen = response.klassenVorhandenIds;
            } else {
              // this.showKlassen = response.klassenVorhandenIds;
              this.showKlassen = JSON.parse(localStorage.getItem('f200_filterClass'));
            }
            this.filterAlleEntlasszeitenAlle();
          }
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
          this.offline = true;
        },
        complete: () => {
          this.loading = false;
          this.offline = false;
        },
      });
    },
    async tryDownloadDokument(path, name) {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
        method: 'POST',
        data: {
          code: this.$store.state.login.session.code,
          file: path,
        },
        xhrFields: {
          responseType: 'blob',
        },
        success: (data) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = name;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die angeforderte Datei konnte nicht heruntergeladen werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
  },
  created() {
    // this.tryGetAusnahmen();
    this.getAusnahmenHeute();
  },
};
</script>
